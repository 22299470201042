import React from 'react';
import { styled } from "linaria/react";
import MaxWidth from '../../Layout/MaxWidth';
import { ProductGrid } from '../../CategoryPage/ProductGrid';

import { theme } from "../../Theme";

const Container = styled('div')`
  padding-top: 48px;
  padding-bottom: 48px;
  ${theme.below.sm} {
    padding-top: 20px;
    padding-bottom: 4px;
    > div {
      padding-right: 0;
    }
  }
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
/* const StyledProductGrid = styled(ProductGrid)`
  flex-wrap: nowrap;
  ${({ theme }) => theme.below.md} {
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }

    padding-bottom: 2rem;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${({ theme }) => theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 150px;
    }
  }
  ${({ theme }) => theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-gap: calc(10px / 2);
    grid-template-columns: repeat(
      ${props => props.products.length},
      calc(25% - 10px * 2)
    );
    grid-template-rows: minmax(150px, 1fr);

    overflow-x: scroll;
    scroll-snap-type: x proximity;
    margin: 0;
    ${({ theme }) => theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
      grid-gap: calc(30px / 2);
    }
  }
`; */

const StyledProductGrid = styled(ProductGrid)`
  &.dynamic-cols {
    grid-template-columns: repeat(${({ cols }) => (cols ? cols : 5)}, 1fr);

    ${theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${theme.below.md} {
          margin-left:-.5rem;
        }
  }
`;

const StartPageProductGrid = ({ products }) => {
  return (
    <Container>
      <MaxWidth>
        <StyledProductGrid
          className="dynamic-cols"
          products={products}
          cols={products.length}
          imageSizes={[1 / 2, 1 / 2, 1 / 4]}
        />
      </MaxWidth>
    </Container>
  );
};

export default StartPageProductGrid;
